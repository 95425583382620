import * as React from "react";
import type { HeadFC } from "gatsby";
import { useEffect } from "react";
import Header_Layout from "../../components/layouts/Header_Layout";

const ConditionsPage = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <Header_Layout>
      <div
        name="termly-embed"
        data-id="254e40a7-8553-4ae5-8b4a-7454067534a8"
        data-type="iframe"
      ></div>
    </Header_Layout>
  );
};

export default ConditionsPage;

export const Head: HeadFC = () => <title>Cookie Policy</title>;
